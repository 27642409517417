export default {
    timezoneMask: {
        mask: 'UTCS##:##',
        tokens: {
            '#': { pattern: /\d/ },
            'S': { pattern: /[+-]/ }
        }
    },
    required: value => !!value || 'Obrigatório.',
    linkName: value => {
        const pattern = /^[a-zA-Z0-9-]*$/
        return pattern.test(value) || 'Permitido apenas letras maiúsculas e minúsculas, números e hífen'
    },
    min5: value => (value && value.length >= 5) || 'Pelo menos 5 caracteres',
    min10: value => (value && value.length >= 10) || 'Pelo menos 10 caracteres',
    email: value => {
        // /.{1,}@.{2,}\..{2,}/
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Email inválido.'
    },
    cellphone: value => {
        const pattern = /^\([0-9]{2}\)\s[0-9]{4,5}-[0-9]{4}$/
        return !value || pattern.test(value) || 'Telefone inválido Ex.: (00) 91234-1234.'
    },
    phone: value => {
        const pattern1 = /^\([0-9]{2}\)\s[0-9]{4,5}-[0-9]{4}$/
        const pattern2 = /^[0-9]{4}\s[0-9]{3}\s[0-9]{4}$/
        return !value || pattern1.test(value) || pattern2.test(value) || 'Telefone inválido Ex.: (00) 91234-1234 ou (00) 1234-1234 ou 0800 123 1234.'
    },
    cep: value => {
        //'#####-###'
        const pattern = /^\d{5}-\d{3}$/
        return !value || pattern.test(value) || 'CEP inválido.'
    },
    money: value => {
        const pattern = /^\d{1,3}.\d{2}$/
        return !value || pattern.test(value) || 'Valor inválido. Ex. 0.50'
    },
    range: (min, max) => (value) => !value || (value >= min && value <= max) || `Fora da faixa ${min} ~ ${max}`,
    time: value => {
        const pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
        return !value || pattern.test(value) || 'Valor inválido. Ex. 00:00 ~ 23:59'
    },
    timezone: value => {
        const pattern = /^UTC[-+]{1}\d{2}:\d{2}$/
        return !value || pattern.test(value) || 'Valor inválido, Ex. UTC-03:00'
    },
    cnpj: value => {
        //'##.###.###/####-##'
        const pattern = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/
        return !value || pattern.test(value) || 'CNPJ inválido.'
    },
    coordinate: value => {
        // '-##.######, -##.######'
        const pattern = /^-?\d{1,2}.\d{6}, -?\d{1,2}.\d{6}$/
        return !value || pattern.test(value) || 'Coordenadas inválidas.'
    },
    password: value => {
        var strength = 0;
        if (value && value.length > 5) {
            if (value.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
                strength += 1;
            }
            if (value.match(/([a-zA-Z])/) && value.match(/([0-9])/)) {
                strength += 1;
            }
            if (value.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
                strength += 1;
            }
            if (value.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,",%,&,@,#,$,^,*,?,_,~])/)) {
                strength += 1;
            }
        }
        return strength >= 2 || "Escolha uma senha de 6 a 15 caracteres, contendo números, símbolos, letras maiúsculas e minúsculas."
    }
};