<template>
    <v-card class="mx-auto mt-4" max-width="800" color="secondary" dark>
        <v-card-title>
            <v-icon large left> settings_applications </v-icon>
            <span class="title font-weight-light">Preferências</span>
        </v-card-title>
        <v-card-text>
            <div class="my-2">
                <v-alert v-if="notificationStatus == 1" type="success">Notificação habilitada.</v-alert>
                <v-alert v-else-if="notificationStatus == -2" type="error">Dispositivo não suporta notificação.</v-alert>
                <v-alert v-else-if="notificationStatus == -1" type="error">
                    Notificações bloqueadas pelo usuário. Para habilitar verifique as configurações do seu navegador.
                </v-alert>
                <v-btn v-else x-large color="success" @click="notifyMe" dark :disable="saving">Habilitar notificação</v-btn>

                <v-switch v-model="preferences.car" inset color="success">
                    <template v-slot:label> <v-icon left>electric_car</v-icon>Usuário de carro elétrico </template>
                </v-switch>
                <v-switch v-model="preferences.bike" inset color="success">
                    <template v-slot:label> <v-icon left>electric_bike</v-icon>Usuário de bicicleta elétrica </template>
                </v-switch>

                <v-btn color="success" @click="sendPreferences" :loading="saving" :disable="saving" style="width: 100%;">Salvar</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {

    mounted() {
        this.checkNotificationStatus();
    },

    data() {
        return {
            saving: false,
            valid: true,
            notificationStatus: -1,
            preferences: {
                bike: this.$store.state.user.bike,
                car: this.$store.state.user.car,
            },
        };
    },

    methods: {
        sendPreferences() {
            this.saving = true;
            this.$http
                .put("api/v2/user/preferences", this.$store.state.user.ID, this.preferences)
                .then((result) => {
                    this.saving = false;
                    this.$store.commit("CHANGE_PREFERENCES", { car: this.preferences.car, bike: this.preferences.bike });
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Preferências alterada com sucesso.");
                    this.$emit("close");
                })
                .catch((error) => {
                    this.saving = false;
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Não foi possível enviar sua solicitação.");
                });
        },
        checkNotificationStatus() {
            if (!("Notification" in window)) {
                this.notificationStatus = -2;
            } else if (Notification.permission === "granted") {
                this.notificationStatus = 1;
            } else if (Notification.permission !== "denied") {
                this.notificationStatus = 0;
            } else {
                this.notificationStatus = -1;
            }
        },
        notifyMe() {
            Notification.requestPermission().then(() => {
                this.checkNotificationStatus();
            });
        },
    },
};
</script>