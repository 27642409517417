import Home from '@/pages/Home';
import Now from '@/pages/Now';

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/helpers/store'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Home,
        meta: {}
    },
    {
        path: '/privacy',
        component: () => import('@/pages/Privacy'),
        meta: {}
    },
    {
        path: '/terms',
        component: () => import('@/pages/Terms'),
        meta: {}
    },
    {
        path: '/receipt',
        component: () => import('@/pages/Receipt'),
        meta: {}
    },

    {
        path: "/confirmregister/:token",
        component: () => import('@/pages/ConfirmRegister'),
        meta: {}
    },
    {
        path: "/recovery/:token",
        component: () => import('@/pages/Recovery'),
        meta: {}
    },
    {
        path: "/resident/:code",
        component: () => import('@/pages/Resident'),
        meta: {}
    },

    // USER ROLES
    {
        path: '/now/:charger',
        component: Now,
        meta: {
            // role: 'ROLE_USER',
        }
    },
    {
        path: '/now/:charger/:plug',
        component: Now,
        meta: {
            // role: 'ROLE_USER',
        }
    },
    // {
    //     path: '/now/:charger/:plug/:paymentStatus',
    //     component: Now,
    //     meta: {
    //         // role: 'ROLE_USER',
    //     }
    // },
    {
        path: '/payment/:charger/:plug/:user/:price/:plataform',
        component: () => import('@/pages/Now/PayFeedback'),
        meta: {
            // role: 'ROLE_USER',
        }
    },
    {
        path: '/payment/:charger/:plug/:user/:price/:plataform/:paymentStatus',
        component: () => import('@/pages/Now/PayFeedback'),
        meta: {
            // role: 'ROLE_USER',
        }
    },
    {
        path: '/ebike/:id',
        component: () => import('@/pages/EBike'),
        meta: {
            // role: 'ROLE_USER',
        }
    },
    {
        path: '/ebike/:id/:plugNumber',
        component: () => import('@/pages/EBikePlug'),
        meta: {
            // role: 'ROLE_USER',
        }
    },
    {
        path: '/ebike/:id/:plugNumber/:statusPayment',
        component: () => import('@/pages/EBikePlug'),
        meta: {
            // role: 'ROLE_USER',
        }
    },
    {
        path: '/mysession',
        component: () => import('@/pages/MySession'),
        meta: {
            role: 'ROLE_USER',
            menu: {
                title: 'Sessões Ativas',
                icon: 'electrical_services',
            },
        }
    },
    {
        path: '/mycharges',
        component: () => import('@/pages/MyCharges'),
        meta: {
            role: 'ROLE_USER',
            menu: {
                title: 'Carregamentos',
                icon: 'description',
            },
        }
    },
    {
        path: '/mycards',
        component: () => import('@/pages/MyCards'),
        meta: {
            role: 'ROLE_USER',
            menu: {
                title: 'Meus Cartões',
                icon: 'wallet',
            },
        }
    },
    {
        path: '/card/:id',
        component: () => import('@/pages/Card'),
    },

    //CLIENT ROLES
    {
        path: '/clientreport',
        component: () => import('@/pages/ClientReport'),
        meta: {
            role: 'ROLE_CLIENT',
            menu: {
                title: 'Relatório de Carregamentos',
                icon: 'description',
            },
        }
    },

    //BIKE CLIENT ROLES
    {
        path: '/clientreportBike',
        component: () => import('@/pages/ClientReportBike'),
        meta: {
            role: 'ROLE_CLIENT_BIKE',
            menu: {
                title: 'Relatório de Carregamentos',
                icon: 'description',
            },
        }
    },
    {
        path: '/clientChargerBike',
        component: () => import('@/pages/ClientChargerBike'),
        meta: {
            role: 'ROLE_CLIENT_BIKE',
            menu: {
                title: 'Carregadores',
                icon: 'ev_station',
            },
        }
    },

    //ADMIN CONDOMINIUM ROLES
    {
        path: '/condominiumReport',
        component: () => import('@/pages/CondominiumReport'),
        meta: {
            role: 'ROLE_ADMIN_CONDOMINIUM',
            menu: {
                title: 'Relatório de Carregamentos',
                icon: 'description',
            },
        }
    },
    {
        path: '/residents',
        component: () => import('@/pages/Residents'),
        meta: {
            role: 'ROLE_ADMIN_CONDOMINIUM',
            menu: {
                title: 'Moradores',
                icon: 'groups',
            },
        }
    },
    // OPERATOR ROUTES
    {
        path: '/ChargerDashboard',
        component: () => import('@/pages/ChargerDashboard'),
        meta: {
            role: 'ROLE_OPERATOR',
            menu: {
                title: 'Carregadores Dashboard',
                icon: 'dashboard',
            },
        }
    },
    {
        path: '/charger/:name',
        component: () => import('@/pages/ChargerView'),
        meta: {
            role: 'ROLE_ADMIN',
        }
    },
    {
        path: '/board/:name',
        component: () => import('@/pages/BoardView'),
        meta: {
            role: 'ROLE_ADMIN',
        }
    },
    {
        path: '/activesession',
        component: () => import('@/pages/ActiveSession'),
        meta: {
            role: 'ROLE_OPERATOR',
            menu: {
                title: 'Sessões Ativas',
                icon: 'electrical_services',
            },
        }
    },
    {
        path: '/payments',
        component: () => import('@/pages/Payment'),
        meta: {
            role: 'ROLE_OPERATOR',
            menu: {
                title: 'Pagamentos',
                icon: 'attach_money',
            },
        }
    },
    {
        path: '/cards',
        component: () => import('@/pages/Cards'),
        meta: {
            role: 'ROLE_OPERATOR',
            menu: {
                title: 'Cartões',
                icon: 'wallet',
            },
        }
    },

    // ADMIN ROUTES
    {
        path: '/condominium',
        component: () => import('@/pages/Condominium'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Condomínios',
                icon: 'apartment',
            },
        }
    },
    {
        path: '/contact',
        component: () => import('@/pages/Contact'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Contatos',
                icon: 'contacts',
            },
        }
    },
    {
        path: '/chargerAdmin',
        component: () => import('@/pages/ChargerAdmin'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Carregadores',
                icon: 'ev_station',
            },
        }
    },
    {
        path: '/chargeHistory',
        component: () => import(/* webpackChunkName: "administrator" */ '@/pages/ChargeHistory'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Histórico de Cargas',
                icon: 'description',
            },
        }
    },
    {
        path: '/point',
        component: () => import(/* webpackChunkName: "administrator" */ '@/pages/Point'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Pontos Mapa',
                icon: 'map',
            },
        }
    },
    {
        path: '/charger',
        component: () => import(/* webpackChunkName: "administrator" */ '@/pages/Charger'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Carregadores (Avançado)',
                icon: 'settings_suggest',
            },
        }
    },
    {
        path: '/board',
        component: () => import(/* webpackChunkName: "administrator" */ '@/pages/Board'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Gateway',
                icon: 'router',
            },
        }
    },
    {
        path: '/vpn',
        component: () => import(/* webpackChunkName: "administrator" */ '@/pages/VPN'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'VPN',
                icon: 'vpn_key',
            },
        }
    },
    {
        path: '/group',
        component: () => import(/* webpackChunkName: "administrator" */ '@/pages/Group'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Grupos de Usuários',
                icon: 'group',
            },
        }
    },
    {
        path: '/user',
        component: () => import(/* webpackChunkName: "administrator" */ '@/pages/User'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Usuários',
                icon: 'person',
            },
        }
    },
    {
        path: '/city',
        component: () => import(/* webpackChunkName: "administrator" */ '@/pages/City'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Cidades',
                icon: 'location_city',
            },
        }
    },
    {
        path: '/state',
        component: () => import(/* webpackChunkName: "administrator" */ '@/pages/State'),
        meta: {
            role: 'ROLE_ADMIN',
            menu: {
                title: 'Estados',
                icon: 'flag',
            },
        }
    },

    {
        path: '*', component: Home
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((routeTo, routeFrom, next) => {
    store.commit("FIRST_REQUEST", true);

    if (routeTo.path == "/login") {
        store.state.routeBeforeLogin = routeFrom.path;
    }

    // Enter here if route has a role to access
    if (routeTo.meta && routeTo.meta.role) {
        try {
            // If user not logged or not contains permission, send to home page
            if (store.getters.verifyRole(routeTo.meta.role) == false) {
                return next({ path: routeTo.meta.redirectToWhenNotPermit ? routeTo.meta.redirectToWhenNotPermit : "/" });
            }
        } catch (e) {
            return next({ path: "/" });
        }
    }
    next()
})

export default router