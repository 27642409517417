<template>
    <div>
        <div id="wallet_container" v-show="loaded == true"></div>
        <v-progress-circular class="mt-12" size="60" width="12" color="primary" indeterminate v-if="loaded == false" />
        <v-btn color="info" class="ma-12" style="width: 75%" v-if="loaded == false" disabled>
            <div class="spinner hidden" id="spinner"></div>
            <v-icon dark left>check_circle</v-icon>
            Pagar
        </v-btn>
    </div>
</template>

<script>

export default {

    props: {
        chargerName: {
            required: true,
        },
        plugNumber: {
            required: true,
        },
        userId: {
            required: true,
        },
        price: {
            required: true,
        },
    },

    data() {
        return {
            PREFERENCE_ID: null,
            PUBLIC_KEY: null,
            loaded: false,
        };
    },

    mounted() {
        setTimeout(() => {
            this.createPreference();
        }, 3000);
    },

    methods: {
        createPreference() {
            this.$http
                .postAlternativeAPI("api/v2/payment/create", { operator: "mp", chargerName: this.chargerName, plugNumber: this.plugNumber, userId: this.userId, price: this.price })
                .then((result) => {
                    if (result && result && result.id) {
                        this.PREFERENCE_ID = result.id;
                        this.PUBLIC_KEY = result.pk;
                        this.start()
                    }
                }).catch(error => {
                    console.log(error)
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao validar carregador.");
                });

        },
        start() {
            const mp = new window.MercadoPago(this.PUBLIC_KEY, {
                locale: "pt-BR",
            });
            mp.bricks().create("wallet", "wallet_container", {
                initialization: {
                    preferenceId: this.PREFERENCE_ID,
                },
                customization: {
                    texts: {
                        valueProp: 'smart_option',
                    },
                },
            });

            setTimeout(() => {
                this.loaded = true;
            }, 3000);
        },
    }
}

</script>