<template>
    <v-card class="fill-height text-center pt-2" fluid>
        <div v-if="recoverySent == false">
            <v-alert text color="info" class="mx-2" border="left">
                <h3 class="text-h5 font-weight-bold">Esqueceu sua senha?</h3>
                <div>Digite o seu e-mail abaixo e já resolveremos isso.</div>
            </v-alert>

            <v-container class="text-center">
                <v-form ref="form" v-model="valid" @submit.prevent="send">
                    <v-text-field type="email" v-model="email" :rules="[rules.required, rules.email]" label="E-mail" required />

                    <v-btn color="success" width="25%" @click="send" :loading="saving">Enviar</v-btn>
                    <v-btn color="error" width="25%" class="mx-sm-1" @click="close">Fechar</v-btn>
                </v-form>
            </v-container>
        </div>
        <div v-else>
            <v-alert text color="success" class="mx-12" border="left">
                <h3 class="text-h5 font-weight-bold">E-mail enviado com sucesso.</h3>
                <div>Enviamos um link para seu e-mail, entre e clique no link para podermos recriar sua senha. <br><br>Lembre de olhar a caixa de
                    <b>SPAM.</b>
                </div>
            </v-alert>
            <v-btn class="my-2" width="50%" color="success" @click="close">Fechar</v-btn>
        </div>
    </v-card>
</template>

<script>
import rules from "@/helpers/rules";

export default {

    props: {
        email: {
            type: String,
        },
    },

    mounted() {
        this.$store.commit("CLEAR_LOGIN");
    },

    data() {
        return {
            rules,
            valid: true,
            saving: false,
            recoverySent: false,
        };
    },

    methods: {
        close() {
            this.recoverySent = false;
            this.$emit("close");
        },
        send() {
            if (this.$refs.form.validate()) {
                this.saving = true;
                this.$http
                    .post("api/v2/request-recovery", { email: this.email })
                    .then(() => {
                        this.saving = false;
                        this.recoverySent = true;
                    })
                    .catch((error) => {
                        this.saving = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Não foi possível enviar sua solicitação.");
                    });
            }
        },
    },
};
</script>