<template>
    <v-dialog v-model="show" @keydown.esc="close" @keydown.enter="close" max-width="600">
        <ToolBarModal />
        <v-card class="fill-height pt-2 text-center" fluid>
            <v-container grid-list-xl fluid>
                <v-expansion-panels v-model="panel" focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="info--text">Preferências
                            <template v-slot:actions>
                                <v-icon color="info">edit</v-icon>
                            </template></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <Preferences @close="closePanel" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="info--text">Alterar senha
                            <template v-slot:actions>
                                <v-icon color="info">edit</v-icon>
                            </template></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <ChangePassword @close="closePanel" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-btn class="my-2 mt-12" width="75%" color="error" @click="close">Fechar</v-btn>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import ChangePassword from "./ChangePassword.vue";
import Preferences from "./Preferences.vue";
import ToolBarModal from "@/components/core/ToolBarModal.vue";

export default {
    components: { ToolBarModal, ChangePassword, Preferences },

    mounted() {
        this.$eventHub.$on("openSettings", () => {
            this.show = true;
        });
    },

    data() {
        return {
            panel: null,
            show: false,
        };
    },
    methods: {
        close() {
            this.show = false;
        },
        closePanel() {
            this.panel = null;
        }
    }
};
</script>