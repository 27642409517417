<template>
    <v-card class="mx-auto mt-4" max-width="800" color="secondary" dark>
        <v-card-title>
            <v-icon large left> vpn_key </v-icon>
            <span class="title font-weight-light">Alterar senha</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" @submit.prevent="send">
                <v-text-field class="my-8" label="Digite a senha atual" v-model="input.oldPassword" min="8" :rules="[rules.required]"
                    :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" counter required />

                <v-text-field label="Digite a nova senha" v-model="input.newPassword" min="8" :rules="[rules.required, rules.password]"
                    :append-icon="show2 ? 'visibility' : 'visibility_off'" :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2" counter required />
                <v-text-field label="Confirme a nova senha" v-model="confirmPassword" min="8" :rules="confirmPasswordRules" :append-icon="show3 ? 'visibility' : 'visibility_off'"
                    :type="show3 ? 'text' : 'password'" @click:append="show3 = !show3" counter required />
                <v-btn color="success" @click="send" :loading="saving" :disable="saving" style="width: 100%;">Salvar</v-btn>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import rules from "@/helpers/rules";


export default {

    data() {
        return {
            rules,
            saving: false,
            valid: true,
            confirmPassword: null,
            show1: false,
            show2: false,
            show3: false,
            input: {
                newPassword: null,
                oldPassword: null,
            },
            confirmPasswordRules: [(v) => v === this.input.newPassword || "Senha diferentes"],
        };
    },

    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.saving = true;
                this.$http
                    .put("api/v2/user/password", this.$store.state.user.ID, this.input)
                    .then((result) => {
                        this.saving = false;
                        this.$store.commit("CHANGE_PASSWORD", this.input.newPassword);
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Senha alterada com sucesso.");
                        this.$emit("close");
                    })
                    .catch((error) => {
                        this.saving = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Não foi possível enviar sua solicitação.");
                    });
            }
        },
    },
};
</script>