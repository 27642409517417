<template>
    <v-card class="fill-height pt-2 pb-2" fluid>
        <v-container class="text-center">
            <div v-if="registerSent == false">
                <v-alert text color="info" class="mx-2" border="left">
                    <h3 class="text-h5 font-weight-bold">Cadastre-se na INCHARGE!</h3>
                    <div>Para ter acesso aos serviços prestados pela INCHARGE, preencha o formulário abaixo.</div>
                </v-alert>
                <v-form ref="form" v-model="valid" @submit.prevent="validate">
                    <v-text-field v-model="item.name" :rules="[rules.required, rules.min5]" label="Como você quer ser chamado" required />
                    <v-text-field type="email" v-model="item.email" :rules="[rules.required, rules.email]" label="E-mail" required />
                    <v-text-field type="tel" v-model="item.phone" :rules="[rules.required, rules.cellphone]" label="Celular" v-mask="['(##) ####-####', '(##) #####-####']"
                        return-masked-value required />
                    <v-btn color="success" width="25%" @click="validate" :loading="saving">Salvar</v-btn>
                    <v-btn class="ma-2" width="25%" color="error" @click="close">Fechar</v-btn>
                </v-form>
            </div>
            <div v-else>
                <v-alert text color="success" class="mx-12" border="left">
                    <h3 class="text-h5 font-weight-bold">E-mail enviado com sucesso.</h3>
                    <div>Enviamos um link para seu e-mail, entre e clique no link para podermos confirmar seu email e continuarmos o cadastro. <br><br>Lembre de olhar a caixa de
                        <b>SPAM.</b>
                    </div>
                </v-alert>
                <v-btn class="my-2" width="50%" color="success" @click="close">Fechar</v-btn>
            </div>
        </v-container>
    </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    data() {
        return {
            rules,
            saving: false,
            valid: true,
            registerSent: false,
            item: {
                name: "",
                email: "",
                phone: "",
            },
        };
    },

    methods: {
        close() {
            this.registerSent = false;
            this.$emit("close");
        },
        validate() {
            if (this.$refs.form.validate()) {
                if (this.$router.currentRoute && this.$router.currentRoute.path) {
                    this.item.redirect = this.$router.currentRoute.path;
                }
                this.saving = true;
                this.$http
                    .post("api/v2/register", this.item)
                    .then(() => {
                        this.saving = false;
                        this.registerSent = true;
                    })
                    .catch((error) => {
                        this.saving = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Não foi possível enviar sua solicitação.");
                    });
            }
        },
    },

    created() {
        this.$store.commit("CLEAR_LOGIN");
    },
};
</script>